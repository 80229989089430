<template>
  <div class="container">
    <div class="header">
      <div class="bg">
        <div class="img">
          <img src="@/assets/images/vip-info/bg.png" alt="">
          <div class="user-info">
            <div class="left">
              <div class="avatar">
                <img :src="$getimgsrc(vipuser.avatar)" alt="">
              </div>
              <div class="name-tel">
                <div class="name">{{ vipuser.realname }}</div>
                <div class="tel">{{  vipuser.mobile}}</div>
                <div class="btn">{{vipuser.dengji}}</div>
              </div>
            </div>
            <div class="right">
              <b>¥{{ vipuser.money }}</b>
              <div>账户余额</div>
            </div>
            <div class="right">
              <b>{{  vipuser.score}}</b>
              <div>可用积分</div>
            </div>
            <div class="right">
              <b>{{  vipuser.draft}}</b>
              <div>成长值</div>
            </div>
          </div>
          <div class="vip-logo">
            <img src="@/assets/images/vip-info/vip-logo.png" alt="">
          </div>
        </div>
      </div>
      <div>
        <img src="@/assets/images/vip-info/bg.png" alt=""
          style="margin-top:-50px; width:95%;max-width:850px;opacity:0;">
      </div>
      <div class="main">
        <div class="info">
          <div class="title">详细资料</div>
          <div class="list">
            <div class="l-list">
              <div class="left">手机</div>
              <div class="right">{{  vipuser.mobile}}</div>
            </div>
            <div class="l-list">
              <div class="left">性别</div>
              <div class="right">{{  vipuser.gender}}</div>
            </div>
            <div class="l-list">
              <div class="left">会员等级</div>
              <div class="right">{{  vipuser.dengji}}</div>
            </div>
            <div class="l-list">
              <div class="left">生日</div>
              <div class="right">{{ vipuser.birthday }}</div>
            </div>
            <div class="l-list">
              <div class="left">负责顾问</div>
              <div class="right">{{  vipuser.brief}}</div>
            </div>
            <div class="l-list">
              <div class="left">注册日期</div>
              <div class="right">{{  vipuser.createtime | timeform(false)}}</div>
            </div>
          </div>
        </div>
        <div class="info mark">
          <div class="title">
            备注信息
          </div>
          <div class="content">
            {{ vipuser.bio }}
          </div>
        </div>
        <!-- <div class="info label">
          <div class="title">
            用户标签
          </div>
          <div class="label-list">
            <div class="label-card" v-for="(item,i) in 10" :key="i">
              标签名{{item}}
            </div>
          </div>
        </div> -->
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
  export default {
    data() {
      return {
        
      }
    },
    computed: {
      ...mapState(['vipuser'])
    },
    mounted() {}
  }

</script>

<style lang="less" scoped>
  .container {
    .header {
      background-color: #fff;
      border-radius: 15px;
      box-shadow: 0 0 4px #ccc;
      overflow: hidden;
    }

    .bg {
      position: relative;
      height: 160px;
      background-color: #a0cfff;

      .img {
        position: absolute;
        top: 80px;
        left: 50%;
        transform: translateX(-50%);
        width: 95%;
        max-width: 850px;

        img {
          width: 100%;
        }

        .user-info {
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          display: flex;
          justify-content: space-around;
          align-items: center;
          width: 100%;
          color: #fff;

          .left {
            display: flex;
            justify-content: flex-start;
            align-items: center;

            .avatar {
              margin-right: 10px;

              img {
                width: 40px;
                min-height: 40px;
                border-radius: 100%;
              }
            }

            .name-tel {
              .name {
                font-weight: bold;
                margin-bottom: 5px;
              }

              .btn {
                margin-top: 5px;
                border: 1px solid #fff;
                text-align: center;
                width: 85px;
                line-height: 24px;
              }
            }
          }

          .right {
            text-align: center;

            b {
              font-size: 18px;
            }

            div {
              font-size: 12px;
            }
          }
        }

        .vip-logo {
          position: absolute;
          top: 0px;
          right: 20px;

          img {
            width: 60px;
          }
        }
      }
    }

    .main {
      padding: 0 20px 50px;

      .info {
        color: #666;
        margin-bottom: 30px;

        .title {
          font-weight: bold;
          margin-bottom: 20px;
        }

        .list {
          display: flex;
          justify-content: space-between;
          flex-wrap: wrap;

          .l-list {
            display: flex;
            justify-content: flex-start;
            width: 48%;
            border-bottom: 1px solid #f2f2f2;
            padding: 15px 0;

            .left {
              width: 25%;
              max-width: 100px;
              color: #999;
            }
          }
        }
      }

      .mark {
        .content {
          padding: 10px;
        }
      }

      .label {
        .label-list {
          display: flex;
          justify-content: flex-start;
          flex-wrap: wrap;

          .label-card {
            width: 120px;
            line-height: 30px;
            text-align: center;
            border-radius: 30px;
            border: 1px solid #f2f2f2;
            margin: 5px;
          }
        }
      }
    }
  }

</style>
